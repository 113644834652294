<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->
<myprofile-signup-ui-navigation navMode="hero" navTheme="dark" navLimitedWidth="false"></myprofile-signup-ui-navigation>
<main class="bg-maintenance-image bg-no-repeat bg-cover bg-center h-screen">
    <div class="bg-maintenance-gradient">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
        it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</main>



